<template lang="pug">
  div.row.justify-content-end
    div.col-12
      h1.h4 Tickets
    div.col-12
      div.row.align-items-center
        div.col-12.col-sm-auto
          div.form-check.form-switch(style="font-size:1rem;")
            input.form-check-input#show-del-switch(type="checkbox", v-model="all", @change="get")
            label.form-check-label(for="show-del-switch") 全て取得する
        div.col-6.col-sm-3.col-lg-2.col-xxl-1
          input.form-control(type="number", placeholder="User ID", v-model.number="user_id_filter", @change="filter_user_id")
        div.col-6.col-sm-3.col-lg-2.col-xxl-1
          input.form-control(type="number", placeholder="Target ID", v-model.number="target_v2_id_filter", @change="filter_target_v2_id")
    div.col-12.mt-2
      VueGoodTable.tickets(:columns="columns", :rows="tickets", :pagination-options="pagination_options", styleClass="vgt-table striped condensed", :sort-options="sort_options", @on-row-click="onRowClick")
        //- Header
        template(slot="table-column", slot-scope="props")
          span(v-if="props.column.field == 'featured'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16"><path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/></svg>
          span(v-else-if="props.column.field == 'at'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16"><path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/></svg>
          span(v-else-if="props.column.field == 'rank'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-award" viewBox="0 0 16 16"><path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z"/><path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/></svg>
          span(v-else-if="props.column.field == 'tags'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tags" viewBox="0 0 16 16"><path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z"/><path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z"/></svg>
          span(v-else, style="font-size: 14px;")
            | {{ props.column.label }}
        //- Row
        template(slot="table-row", slot-scope="props")
          div(v-if="props.column.field == 'title'")
            span {{ props.row.title }}
            span.hint(v-if="props.row.target_v2")  <br>Target: {{ props.row.target_v2 }}
            span.hint(v-else-if="props.row.user")  <br>User: {{ props.row.user }}
          span(v-else-if="props.column.field == 'type'")
            | {{ types[props.row.type] }}
          span(v-else-if="props.column.field == 'status'")
            | {{ status[props.row.status] }}
          div.priority(v-else-if="props.column.field == 'priority'", :data-priority="props.row.priority")
            | {{ priority[props.row.priority] }}
          span(v-else-if="props.column.field == 'created_at'")
            | {{ moment.unix(props.row.created_at).format('YYYY/MM/DD HH:mm') }}
          span(v-else-if="props.column.field == 'updated_at'")
            | {{ moment.unix(props.row.updated_at).format('YYYY/MM/DD HH:mm') }}
          span(v-else)
            | {{ props.formattedRow[props.column.field] }}


</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';

export default {
  name: "Tickets",
  components: {
    VueGoodTable
  },
  data (){
    return {
      moment:moment,
      all:false,
      orginal_tickets:[],
      tickets:[],
      pagination_options: {
        enabled: true,
        perPage: 20,
        perPageDropdown: [20, 50, 100]
      },
      sort_options:{
        enabled:true,
        initialSortBy:{field:'id', type:'desc'}
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled:true }
        },
        {
          label: 'Type',
          field: 'type',
          width: '80px',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled:true,
            placeholder:"All",
            filterDropdownItems: [  
              { value:1, text: 'Watch' },  
              { value:2, text: 'Bug' },  
              { value:3, text: 'Feature' },
              { value:4, text: 'Support' }  
            ]
          }
        },
        {
          label: 'Status',
          field: 'status',
          width: '100px',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled:true,
            placeholder:"All",
            filterDropdownItems: [  
              { value:1, text: 'New' },  
              { value:2, text: 'InProgress' },  
              { value:3, text: 'Resolved' },
              { value:4, text: 'Feedback' },
              { value:5, text: 'Closed' },
              { value:6, text: 'Rejected' }
            ]
          }
        },
        {
          label: 'Priority',
          field: 'priority',
          width: '100px',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled:true,
            placeholder:"All",
            filterDropdownItems: [  
              { value:1, text: 'Low' },  
              { value:2, text: 'Normal' },  
              { value:3, text: 'High' },
              { value:4, text: 'Urgent' },
              { value:5, text: 'Immediate' }
            ]
          }
        },
        {
          label: 'Title',
          field: 'title',
          sortable: false,
          filterOptions: {
            enabled:true
          }
        },
        {
          label: 'Created',
          field: 'created_at',
          width: '135px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Updated',
          field: 'updated_at',
          width: '135px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      status:[
        "",
        "New",
        "InProgress",
        "Resolved",
        "Feedback",
        "Closed",
        "Rejected"
      ],
      types:[
        "",
        "Watch",
        "Bug",
        "Feature",
        "Support"
      ],
      priority:[
        "",
        "Low",
        "Normal",
        "High",
        "Urgent",
        "Immediate",
      ],
      user_id_filter:null,
      target_v2_id_filter:null,
    }
  },
  async mounted (){
    await this.get();
  },
  methods: {
    async get (){
      try {
        let url = "/api/tickets";
        if (this.all)
          url = url + "?all";
        
        let res = await this.axios.get(url);
        this.orginal_tickets = res.data.tickets;
        this.tickets = [...res.data.tickets];
      }
      catch(e){
        this.$swal("Error", "", "error");
      }
    },
    filter_user_id (){
      if (this.user_id_filter)
        this.tickets = this.orginal_tickets.filter(e=>e.user_id==this.user_id_filter);
      else
        this.tickets = [...this.orginal_tickets];
    },
    filter_target_v2_id (){
      if (this.target_v2_id_filter)
        this.tickets = this.orginal_tickets.filter(e=>e.target_v2_id==this.target_v2_id_filter);
      else
        this.tickets = [...this.orginal_tickets];
    },
    async onRowClick(params) {
      await this.$parent.get_ticket(params.row.id);
    }
  }
}
</script>

<style lang="scss" scoped>
.tickets ::v-deep {
  td {
    font-size:14px;
    vertical-align: middle;
  }
  .hint {
    color: #a0a0a0;
    font-size: 13px;
  }
  .priority {
    width: 100%;
    &[data-priority="1"] {
      background-color: #EBF7FE;
    }
    &[data-priority="3"] {
      background-color: #FDEFEF;
    }
    &[data-priority="4"] {
      background-color: #FAD7D5;
    }
    &[data-priority="5"] {
      background-color: #F8C8C6;
    }
  }

}
</style>